<template>
  <v-dialog width="340" v-model="showDialog">
    <v-card>
      <v-card-text>
        <div class="text-center">
          <v-chip
              :small="$vuetify.breakpoint.smAndDown"
              class="mb-2 smooth-transition"
              style="display: inline-block"
              @click="isJalali = false"
          >
            {{ $t('clientStepper.dataTime.typeCalender[0]') }}
          </v-chip>
          <div style="display: inline-block">
            <v-switch
                :dense="$vuetify.breakpoint.smAndDown"
                class="ma-3 mx-1 "
                v-model="isJalali"
                hide-details
                color="primary"
            >
            </v-switch>
          </div>
          <v-chip
              :small="$vuetify.breakpoint.smAndDown"
              @click="isJalali = true"
              class="mb-2 smooth-transition"
              style="display: inline-block"
          >
            {{ $t('clientStepper.dataTime.typeCalender[1]') }}
          </v-chip>
        </div>
        <AdvancedDatePicker :flow="{
                                             step1:'year',
                                             step2:'month',
                                             step3:'day',
                                           }"
                            v-if="isJalali" v-model="date"
                            :styles="'font-family: IranSans;padding:0 5px;'"
        >
          <template v-slot:prev-icon>
            <v-btn fab x-small class="elevation-0">
              <v-icon fab small class="grey--text">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:next-icon>
            <v-btn fab x-small class="elevation-0">
              <v-icon fab class="grey--text">
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:small-modal>
          </template>
        </AdvancedDatePicker>

        <template v-else>
          <div class="gorgianCalender">
            <v-date-picker
                first-day-of-week="6"
                class="elevation-0"
                :width="'100%'"
                v-model="date"
            ></v-date-picker>
          </div>
        </template>

      </v-card-text>
      <v-card-actions v-if="showActions">
        <v-btn class="error">{{ $t('clientStepper.offlinePayment.close') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="success">{{ $t('clientStepper.confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AdvancedDatePicker from '@/components/Calendar/AdvanceDatePicker';
import momentJalali from 'moment-jalaali';

export default {
  methods: {
    convertToMiladi(date) {
      return date;
      // return this.isJalali ? momentJalali(date, 'jYYYY-jMM-jDD').format('YYYY-MM-DD') : date;
    }
  },
  components: {
    AdvancedDatePicker
  },
  props: {
    defaultDate: {
      default: null
    },
    showActions: {
      default: false
    },
    value: {
      default: null
    },
    dialog: {
      default: false
    }
  },
  mounted() {
    if (this.defaultDate != null) {
      this.date = momentJalali(this.defaultDate, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
    }
  },
  data() {
    return {
      lock: false,
      showDialog: false,
      isJalali: true,
      date: null,
    }
  },
  watch: {
    isJalali(newVal) {
      if (this.date != null) {
        this.lock = true;
        if (newVal) {
          this.date = momentJalali(this.date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
        } else {
          this.date = momentJalali(this.date, 'jYYYY-jMM-jDD').format('YYYY-MM-DD')
        }
      }
      this.$emit('jalaliChange', newVal);
    },
    dialog(newVal) {
      this.showDialog = newVal;
    },
    showDialog(newVal) {
      if (!newVal) {
        this.$emit('close');
      }
    },
    date(newVal) {
      if (!this.lock) {
        if (newVal != null && !this.showActions) {
          this.$emit("input", this.convertToMiladi(this.date));
          this.$emit('close');
        }
      } else {
        this.lock = false;
      }
    }
  }
}
</script>

<style>
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  width: 100%;
}

.gorgianCalender .v-btn__content{
  font-family: en-light !important ;
}
.gorgianCalender .accent--text button{
  font-family: en-light !important ;
}
.gorgianCalender .v-date-picker-title__year{
  font-family: en-light !important ;
}


</style>