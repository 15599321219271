<template>
  <div class="BoxArea mt-10">
    <v-form ref="profile" @change="formChanged">
      <v-row>
        <v-col cols="12" lg="10">
          <div>
            <div class="titleProfileSection">
              <v-icon small color="#4597DF">mdi-circle</v-icon>
              {{ $t('section_title.personalInfo') }}
            </div>
            <div class="boxProfile pa-10">
              <v-row>
                <v-col cols="12" lg="4">
                  <v-text-field id="UserName" v-model="user.firstname" :rules="requiredRules" outlined
                                class="profileTextFiled"
                                :label="$t('profileView.name')"
                                persistent-hint
                                :hint="$t('profileView.name_hint')"></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field id="UserFamily" :rules="requiredRules" v-model="user.lastname" outlined
                                class="profileTextFiled"
                                :label="$t('profileView.family')"></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <div class="ltrInput">
                    <v-text-field id="UserName" v-model="user.en_name" :rules="requiredRules" outlined
                                  class="profileTextFiled"
                                  :label="$t('profileView.en_name')"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" lg="4">
                  <div class="ltrInput">
                    <v-text-field id="UserFamily" :rules="requiredRules" v-model="user.en_family" outlined
                                  class="profileTextFiled"
                                  :label="$t('profileView.en_family')"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-select item-text="title" item-value="value" v-model="user.gender" :items="genders"
                            :rules="requiredRules" outlined class="profileTextFiled"
                            :label="$t('profileView.sex')"></v-select>
                </v-col>
                <v-col cols="12" lg="4">
                  <div class="phoneNumber">
                    <v-text-field v-model="phone" id="UserTell" :rules="phoneRules" outlined class="profileTextFiled"
                                  :label="$t('profileView.tell')"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field v-model="user.email " readonly disabled
                                :rules="emailRules"
                                outlined
                                class="profileTextFiled"
                                :label="$t('profileView.email')"></v-text-field>
                </v-col>
                <v-col cols="12" lg="4" @click="showDialog = true;isJalali = true">
                  <v-text-field v-model="user.birthDate" :value="user.birthDate | toJalali(isJalali)"
                                :rules="requiredRules" outlined
                                class="profileTextFiled"
                                :label="$t('profileView.birthDate')"
                  ></v-text-field>
                  <DatePicker @close="showDialog=false"
                              :dialog="showDialog"
                              @jalaliChange="changeIsJalali"
                              :defaultDate="user.birthDate"
                              @input="showDialog = false"
                              v-model="user.birthDate"
                  ></DatePicker>
                </v-col>

                <v-col cols="12" lg="4">
                  <v-select :items="maritalStatus" id="UserMarried" v-model="user.married" item-value="key"
                            item-text="title"
                            :rules="requiredRules" outlined class="profileTextFiled"
                            :label="$t('profileView.maritalStatus')"></v-select>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-select :items="education" v-model="user.edu" item-text="title" item-value="value"
                            :rules="requiredRules" outlined
                            class="profileTextFiled"
                            :label="$t('profileView.degree')"></v-select>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-select :items="jobs" id="UserJob" v-model="user.job" item-value="key" item-text="title"
                            :rules="requiredRules"
                            outlined
                            class="profileTextFiled"
                            :label="$t('profileView.job')"></v-select>
                </v-col>
                <v-col cols="12" lg="4">
                  <div class="phoneNumber">
                    <v-select v-model="user.knway" :rules="requiredRules" outlined class="profileTextFiled"
                              :label="$t('knwayLabel')" :items="knway" item-text="title" item-value="value"></v-select>
                  </div>
                </v-col>
                <v-col cols="12" lg="4">
                  <div class="" v-if="user.knway == 'other'">
                    <v-text-field v-model="user.knway_meta" :rules="requiredRules" outlined class="profileTextFiled"
                                  :label="$t('knwayLabel')" :items="knway" item-text="title"
                                  item-value="value"></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="mt-10">
            <div class="titleProfileSection">
              <v-icon small color="#4597DF">mdi-circle</v-icon>
              {{ $t('section_title.location') }}
            </div>
            <div class="boxProfile pa-10">
              <v-row>
                <v-col cols="12" lg="4">
                  <v-autocomplete id="UserCountry" placeholder="تایپ کنید..." :items="contires" item-text="label"
                                  item-value="id"
                                  v-model="user.country_id"
                                  :rules="requiredRules" outlined class="profileTextFiled"
                                  :label="$t('country')"></v-autocomplete>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field :rules="requiredRules" v-model="user.city" id="UserCity" outlined
                                class="profileTextFiled"
                                :label="$t('city')"></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">

                  <v-autocomplete item-text="text" item-value="value" v-model="user.tz" :items="timezonesList"
                                  :rules="requiredRules" outlined class="profileTextFiled"
                                  :label="$t('tz')"
                                  persistent-hint
                                  hint="زمان های نمایش‌ داده بر اساس این منطقه زمانی می‌باشد"></v-autocomplete>
                </v-col>
              </v-row>
              <div class="d-flex justify-end">

              </div>
            </div>
            <!--            <div class="text-left">-->
            <!--              <v-btn color="#3CBE8B" :disabled="!mustSubmit" class="white&#45;&#45;text mt-10 submitChange" large elevation="0"-->
            <!--                     @click="saveProfile">-->
            <!--                {{ $t('edit') }}-->
            <!--              </v-btn>-->
            <!--            </div>-->
          </div>
          <div class="mt-10">
            <div class="titleProfileSection">
              <v-icon small color="#4597DF">mdi-circle</v-icon>
              {{ $t('section_title.password') }}
            </div>
            <div class="boxProfile pa-10">
              <v-form ref="passwordForm">

                <v-row>
                  <v-col cols="12" lg="4">
                    <v-text-field type="password" :rules="passwordRules" hint="رمز عبور باید بیشتر از 8 کاراکتر باشد"
                                  v-model="newPassword" id="UserCity" outlined
                                  class="profileTextFiled"
                                  label="رمز عبور جدید"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-text-field :rules="passwordConfirmRules" type="password"
                                  v-model="newPassword_confirm" outlined
                                  class="profileTextFiled"
                                  label="تکرار رمز عبور جدید"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>

              <div class="d-flex justify-end">

              </div>
            </div>

          </div>
          <div class="mt-10">
            <div class="text-left">
              <v-btn color="#3CBE8B" :disabled="!mustSubmit" class="white--text mt-10 submitChange" large elevation="0"
                     @click="saveProfile">
                {{ $t('edit') }}
              </v-btn>
            </div>
            <!--            <div class="titleProfileSection">-->
            <!--              <v-icon small color="#4597DF">mdi-circle</v-icon>-->
            <!--              {{ $t('section_title.cycles') }}-->
            <!--            </div>-->
            <!--            <div class="cycles">-->
            <!--              <v-row>-->
            <!--                <v-col cols="12" lg="4" v-for="(cycle , i) in profile.cycles" :key="i">-->
            <!--                  <div class="cycleBox" @click="selectCycle(cycle.id)">-->
            <!--                    <div class="titleCycle d-flex justify-space-between">-->
            <!--                      <div>-->
            <!--                        {{ $t('cycle') }}-->
            <!--                        {{ $t(`cycles.${cycle.heading}`) }}-->
            <!--                      </div>-->
            <!--                      <div v-if="cycle.id == selectedCycle">-->
            <!--                        <v-btn fab x-small elevation="0" text>-->
            <!--                          <v-icon x-small>mdi-close</v-icon>-->
            <!--                        </v-btn>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                    <v-fade-transition>-->
            <!--                      <div class="descBox" v-if="selectedCycle == cycle.id">-->
            <!--                        <div class="descriptionCycle mt-2">-->
            <!--                          {{ cycle.problem_description }}-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </v-fade-transition>-->
            <!--                  </div>-->


            <!--                </v-col>-->
            <!--              </v-row>-->
            <!--            </div>-->
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
/*eslint-disable*/

import {mapGetters} from "vuex";
import AuthMixins from "@/mixins/AuthMixins";
import DatePicker from "@/components/Calendar/DatePicker";
import momentJalali from "moment-jalaali";
import moment from 'moment-timezone';
import EventsMixins from "@/mixins/EventsMixins";

export default {
  components: {DatePicker},
  mixins: [AuthMixins, EventsMixins],
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
    }),
    passwordConfirmRules() {
      return [
        v => !!v || this.$t('Notify.signupComponent.passwordRequired'),
        v => (v === this.newPassword) || this.$t('Notify.signupComponent.notSamePassword')
      ];
    },
    // user() {
    // },
    formatedBD() {
      if (this.profile.birthDate != null) {
        if (this.$vuetify.rtl) {
          return new Date(this.profile.birthDate).toLocaleDateString('fa-IR');
        } else {
          return this.userInfo.birthDate;
        }
      } else {
        return null;
      }
    }
  },
  mounted() {


    let times = JSON.parse(JSON.stringify(this.timezones));

    this.timezonesList = times.map((item) => {
      item.text = item.text + '  ' + moment.tz(item.text).format('Z');
      return item;
    });

    this.$emitEvent('setLoading', true)
    this.getProfile = false
    this.requesting('auth', 'getProfile').finally(() => {
      this.user = JSON.parse(JSON.stringify(this.profile))
      this.prepareDefaultTimezone()
      this.phone = this.profile.cel
      this.$emitEvent('setLoading', false)
      setTimeout(() => {
        this.getProfile = true
      }, 1000)
    });
  },
  data() {
    return {
      mustSubmit: false,
      isJalali: false,
      selectedCycle: null,
      phone: null,
      showDialog: false,
      user: false,
      getProfile: false,
      timezonesList: null,
      newPassword: null,
      newPassword_confirm: null,
      knway: [
        {value: "google", title: this.$t('knway.google')},
        {value: "twitter", title: this.$t('knway.twitter')},
        {value: "instagram", title: this.$t('knway.instagram')},
        {value: "podcast", title: this.$t('knway.podcast')},
        {value: "bannerads", title: this.$t('knway.bannerads')},
        {value: "friends", title: this.$t('knway.friends')},
        {value: "youtube", title: this.$t('knway.youtube')},
        // {value: "site", title: this.$t('knway.site')},
        {value: "other", title: this.$t('knway.other')},
      ],
      jobs: [
        {
          title: this.$t('jobs.employed'),
          key: 'employed',
        },
        {
          title: this.$t('jobs.unemployed'),
          key: "unemployed"
        },
        {
          title: this.$t('jobs.housewife'),
          key: "housewife",
        },
        {
          title: this.$t('jobs.student'),
          key: "student"
        },
        {
          title: this.$t('jobs.other'),
          key: 'other'
        },
      ],
      education: [
        {value: "nothing", title: this.$t('education.NoDegree')},
        {value: "diploma", title: this.$t('education.Diploma')},
        {value: "associate", title: this.$t('education.AssociateDegree')},
        {value: "bachelor", title: this.$t('education.Bachelor')},
        {value: "master", title: this.$t('education.MA')},
        {value: "doctorate", title: this.$t('education.PHD')}
      ],
      maritalStatus: [
        {
          key: 'single',
          title: this.$t('relationships.single')
        },
        {
          key: 'married',
          title: this.$t('relationships.married')
        },
        {
          key: 'divorced',
          title: this.$t('relationships.divorced')
        },
        {
          key: 'rel',
          title: this.$t('relationships.rel')
        },
        {
          key: 'otherState',
          title: this.$t('relationships.otherState')
        },
      ],
      genders: [
        {
          title: this.$t('gender.male'),
          value: "MALE"
        },
        {
          title: this.$t('gender.female'),
          value: "FEMALE"
        },
        {
          title: this.$t('gender.other'),
          value: "OTHER"
        },
      ]
    }
  },
  watch: {
    user: {
      deep: true,
      handler() {
        if (this.getProfile) {
          this.mustSubmit = true
        }
      }
    },
    newPassword(val) {
      if (val) {
        if (this.$refs.passwordForm.validate()) {
          this.mustSubmit = true
        }
      } else {
        this.mustSubmit = false
      }
    },
    newPassword_confirm(val) {
      if (val) {
        if (this.$refs.passwordForm.validate()) {
          this.mustSubmit = true
        }
      } else {
        this.mustSubmit = false
      }
    }
    // 'user.kenway'(){
    //   console.log('here')
    // }
  },
  methods: {
    prepareDefaultTimezone() {
      try {
        if (this.user) {
          let options = {timeZoneName: 'long'};
          let timezone = Intl.DateTimeFormat(undefined, options).resolvedOptions().timeZone;
          if (timezone) {
            if (this.user.tz == 'UTC') {
              this.user.tz = timezone
            }
          }
        }
      } catch (er) {
        console.log(er)
      }
    },
    formChanged() {
      this.isFormChanged = true;
      // console.log('change')
    },
    selectCycle(id) {
      this.selectedCycle == id ? this.selectedCycle = null : this.selectedCycle = id
    },
    changeIsJalali(val) {
      this.isJalali = val;
    },
    saveProfile() {
      if (this.$refs.profile.validate()) {

        let data = {
          // birth_date: moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          birth_date: this.isJalali ? this.jalaliToGregorian(this.user.birthDate) : this.user.birthDate,
          name: this.user.firstname,
          family: this.user.lastname,
          mobile: this.phone,
          gender: this.user.gender,
          timezone: this.user.tz,
          "familiar_by": this.user.knway,
          "familiar_by_meta": this.user.knway_meta,
          "city": this.user.city,
          "country_id": this.user.country_id,
          "marital_state": this.user.married,
          "education_level": this.user.edu,
          "job": this.user.job,
          "en_name": this.user.en_name,
          "en_family": this.user.en_family,

          // email:this.user.email

        };
        if (this.newPassword) {
          if (this.$refs.passwordForm.validate()) {
            data.password_confirmation = this.newPassword_confirm
            data.password = this.newPassword
          } else {
            return;
          }
        }
        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'editProfile', '', data).then((resp) => {


          this.WebengageSetUserRequiredFiled(resp.data.profile)

          this.$emitEvent('notify', {
            title: this.$t('userOnboarding.notify.done'),
            color: 'green',
            id: 'notify_success'
          })
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      }
    }
  },
  filters: {
    toJalali(date, isJalali) {
      // if (date) {
      //   if (isJalali) {
      //     return momentJalali(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      //   } else {
      //     return date;
      //   }
      // }
      // return date;
    },
  },
  created() {

  }
}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 600px) {
  .profileTextFiled {
    width: 100% !important;
  }

  .boxProfile {
    width: 90% !important;
    margin: 0px auto;
  }
}

@media (min-width: 600px) and (max-width: 1100px) {
  .boxProfile {
    width: 70% !important;
    /*margin: 0px auto;*/
  }

  .profileTextFiled {
    width: 100% !important;
  }
}

.descBox {
  position: absolute;
  box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 18px 18px;
  background: white;
  padding: 15px;
  left: 0px;
  /*top: 0px;*/
  /*bottom: -10px;*/
  right: 0px;
  z-index: 200;
  transition: all 0.3s;
  text-align: justify;
  line-height: 1.3em;
  cursor: pointer;
}


.titleCycle {
  color: #3CBE8B;
  font-size: 18px;
  cursor: pointer;
}

.submitChange {
  background: #3CBE8B;
  border-radius: 9px;
  width: 150px;
  height: 50px;
}

.boxProfile {
  background: #FFFFFF;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  padding: 20px;

}

.cycleBox {
  background: #FFFFFF;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  padding: 20px;
  position: relative;
  transition: all 0.3s;

}


.profileTextFiled {
  border-radius: 12px;
  width: 80%;
}

.descriptionCycle {
  color: #959595;
  font-size: 12px;
}
</style>